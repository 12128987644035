import React, { useEffect, useState } from "react";
import { colors, Blink, BTypography, PathProgress, PaginationList, SortableList, UserAvatar, FilterTextField, FormAutocompleteField, NormalButton, PopTip, ProgramAvatar } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  groupFilter: { paddingLeft: "0.25rem", minWidth: "10rem" },
  programFilter: { paddingLeft: "0.25rem", minWidth: "10rem" },
  activeFilter: { paddingLeft: "0.25rem", minWidth: "10rem" },
  filterWrapper: { paddingLeft: "0.25rem" },
  sharedGroupButton: { padding: "0.5rem 0 0 0.5rem", },
  header: { padding: "1rem", backgroundColor: colors.white },
  body: { padding: "1rem" },
  overview: { borderBottom: `1px solid ${colors.black}` },
  learners: { padding: "1rem 0", paddingBottom: "4rem" },
  column: {
    borderRight: `1px solid ${colors.black}`,
    textAlign: "center",
    padding: "0.5rem",
    maxHeight: "20rem",
    overflowY: "auto",
    "&:last-child": { borderRight: "none" }
  },
  learner: {
    padding: "1rem",
    backgroundColor: colors.white,
    borderBottom: `0.1rem solid ${colors.lightGray}`
  },
  avatarName: { paddingLeft: "0.25rem", overflowWrap: "normal" },
  path: { paddingRight: "0.25rem" },
  subtitle: { paddingLeft: "0.25rem", colors: colors.bildBlue },
  link: { color: colors.lightBlue, textDecoration: "underline", "&:hover": { textDecoration: "none", color: colors.darkBlue } },
  button: { backgroundColor: colors.orange }
});

export default function ReviewPeople({ name, learners, programs, groups, shared }) {
  const cls = useStyles({});
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState(null);
  const [programFilter, setProgramFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(1);

  useEffect(() => {
    // map raw org data into list format
    setItems(
      learners
        .filter(l => {
          if (filter.length > 0) {
            return (
              l.user.name.toLowerCase().includes(filter.toLowerCase()) ||
              l.userPaths.some(up =>
                l.user.name.toLowerCase().includes(filter.toLowerCase())
                || l.userPaths.some(up => up.title.toLowerCase().includes(filter.toLowerCase()))
                || l.userPaths.some(up => up.subtitle && up.subtitle.toLowerCase().includes(filter.toLowerCase()))
              )
            );
          } else {
            return 1;
          }
        })
        .filter(l => {
          if (groupFilter) {
            return groups.filter(g => g.id === groupFilter)[0].users.some(u => u.id === l.user.id);
          } else {
            return 1;
          }
        })
        .filter(l => {
          if (programFilter && programFilter.length > 0) {
            return l.userPaths.some(up => up.title === programFilter);
          } else {
            return 1;
          }
        })
        .filter(l => {
            return l.userPaths.some(up => {
              if (
                ((!activeFilter || activeFilter === 1) && up.active && !up.completed)
                || (activeFilter === 2 && up.completed)
                || (activeFilter === 3 && (up.active || up.completed))
                || (activeFilter === 4 && !up.active && !up.completed)
                || (activeFilter === 5)
              ) {
                return true;
              }
          });
        })
        .sort((a, b) => a.user.name.localeCompare(b.user.name))
        .map((x, i) => [
          [
            <NormalButton
              variant="simple"
              dst="manage-user"
              user_id={x.user.id}
              sortval={x.user.name} 
              key={x.user.id} 
            >
              <UserAvatar size={40} src={x.user.avatarURL} name={x.user.name} nameVariant={"body1"} srcSize="small" badgeSrc={x.user.badgeURL}/>
            </NormalButton>
          ],
          x.userPaths
          .filter(up => {
            // Filter Active Programs
            if (
              ((!activeFilter || activeFilter === 1) && up.active && !up.completed)
              || (activeFilter === 2 && up.completed)
              || (activeFilter === 3 && (up.active || up.completed))
              || (activeFilter === 4 && !up.active && !up.completed)
              || (activeFilter === 5)
            ) {
              return true;
            }
            // Filter Named Programs
            if (
              filter && (
                up.title.toLowerCase().includes(filter.toLowerCase())
                || (up.subtitle && up.subtitle.toLowerCase().includes(filter.toLowerCase()))
              )
            ) {
              return true;
            }
          })
          .map((y, j) => (
            <Grid container key={j} alignItems="center" sortval={y.title}>
              <Grid container item xs={10}>
                <ProgramAvatar
                  userPathId={y.id}
                  size={30}
                  icon={"fas fa-badge-check"}
                  color={y.programColor.webFrontendValue}
                  name={y.title}
                  subtitle={y.subtitle}
                  nameVariant="body1"
                  subtitleVariant="body1"
                />
              </Grid>
              <Grid item xs={2}>
                <PathProgress
                  totalCompletedCoreSteps={y.totalCompletedCoreSteps}
                  totalInProgressCoreSteps={y.totalInProgressCoreSteps}
                  totalCoreSteps={y.totalCoreSteps}
                  totalCompletedSteps={y.totalCompletedSteps}
                  totalInProgressSteps={y.totalInProgressSteps}
                  totalSteps={y.totalSteps}
                />
              </Grid>
            </Grid>
          ))
        ])
    );
  }, [learners, filter, groupFilter, programFilter, activeFilter]);

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="center">
        <Grid item>
          <BTypography variant="h4"><i className="fal fa-poll-people" /> {name ? name : "Progress View"}</BTypography>
        </Grid>
        <Grid container item md sm={12} justifyContent="flex-end" alignItems="center">
          <Grid item className={cls.groupFilter}>
            <FormAutocompleteField placeholder="Group" value={groupFilter} setValue={setGroupFilter} items={groups} variant="small" />
          </Grid>
          <Grid item className={cls.programFilter}>
            <FormAutocompleteField placeholder="Program" value={programFilter} setValue={setProgramFilter} items={programs} variant="small" />
          </Grid>
          <Grid item className={cls.activeFilter}>
            <FormAutocompleteField placeholder="Active" value={activeFilter} setValue={setActiveFilter} items={[{id: 1, name: "Active"}, {id: 2, name: "Completed"}, {id: 3, name: "Active/Completed"}, {id: 4, name: "Inactive"}, {id: 5, name: "All"}]} variant="small" />
          </Grid>
          <Grid item className={cls.filterWrapper}>
            <FilterTextField
              value={filter}
              placeholder="Search"
              onChange={e => setFilter(e.target.value)}
              onClear={() => {
                setFilter("");
              }}
              wrapperPadding={"0"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <SortableList
          perPage={10}
          filter={filter}
          headers={["Name", "Program"]}
          items={items}
          itemType={"People"}
          spacing={[4, 8]}
          itemPadding={"0.5rem 1rem"}
          fixedHeight={"65vh"}
          emptyMessage={<BTypography variant="h6">{filter ? "No Users/Programs match the search criteria." : "No Users/Programs, yet!"}</BTypography>}
        />
      </Grid>
    </Grid>
  );
}
