import { getRequest, putRequest, postRequest } from "../core/comm.js";
import { localStorage as ls, ENUMS } from "..";
import { updateURL, updateURLWithoutLoad } from "bild-utils";

const user_overview_key = "user_overview";
const redirect_url_key = "redirect_url";
const user_preferences_key = "user_preferences";
const user_library_key = "user_library";

//// USER OVERVIEW
export function getOverview() {
  return ls.getItem(user_overview_key);
}

export function setOverview(user_info) {
  ls.setItem(user_overview_key, user_info);
}

export function loadOverview(success_cb, error_cb) {
  getRequest(
    `/users/overview`,
    current => {
      // NOTE: these roles are from the `/help/visualList` API
      current.showInitialReview = current.roles.some(r => r.id === ENUMS.ROLES.LOCAL_MENTOR.ID);
      current.showValidationReview = current.roles.some(r => r.id === ENUMS.ROLES.ASSOCIATE_FACULTY.ID);
      current.showEquipReview = current.equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.LEADER.ID || r.id === ENUMS.EQUIP_ROLES.MENTOR.ID || r.id === ENUMS.EQUIP_ROLES.TEACHER.ID);
      current.showSimaStaff = current.roles.some(r => r.id === ENUMS.ROLES.SIMA_STAFF.ID);
      current.showSimaReview = current.roles.some(r => r.id === ENUMS.ROLES.SIMA_REVIEWER.ID);
      current.showStaffSearch = current.roles.some(r => r.id === ENUMS.ROLES.STAFF.ID);
      current.showSearch = current.roles.some(r => r.id === ENUMS.ROLES.ADMIN_SEARCHER.ID);
      current.isAdmin = current.roles.some(r => r.id === ENUMS.ROLES.ADMIN.ID);
      current.isBetaTester = current.roles.some(r => r.id === ENUMS.ROLES.BETA_TESTER.ID);
      current.isAdminDemoUser = current.roles.some(r => r.id === ENUMS.ROLES.ADMIN_DEMO.ID);
      current.isCoach = [11, 16, 19, 24, 27, 360, 563].includes(current.user.id); // (Use hardcoded list for now, Equip Coaches only)
      current.isMcceeParticipant = current.roles.some(r => r.id === ENUMS.ROLES.MCCEE_COHORT_PARTICIPANT.ID);
      current.isMcceeLeader = current.roles.some(r => r.id === ENUMS.ROLES.MCCEE_NETWORK_LEADER.ID);
      current.showOrganizationReports = current.roles.some(
        r =>
          r.id === ENUMS.ROLES.RTM.ID ||
          r.id === ENUMS.ROLES.LOCAL_MENTOR.ID ||
          r.id === ENUMS.ROLES.ASSOCIATE_FACULTY.ID ||
          r.id === ENUMS.ROLES.ADMIN.ID
      );

      // example of carry over menu states. If doing user display prefrences, handle in userPreferences
      // const old = getOverview();
      // if (old) {
      //   current.mini = old.mini === true;
      // }

      setOverview(current);

      if (typeof success_cb === "function") success_cb(current);
    },
    null,
    error_cb
  );
}

export function deleteOverview() {
  ls.removeItem(user_overview_key);
}

//// USER PREFRENCES
export function getUserPreferences() {
  let current = ls.getItem(user_preferences_key);
  // Initialize User Prefrences if none exist
  if (!current || current.length < 1) {
    const initialUserPreferences = {
      "mini": false,
      "worldMapLayers": ["Light Gray Map", "Heatmap", "Heatmap Legend"],
      "filePreviewFullscreen": "false"
    };
    setUserPreferences(initialUserPreferences);
    current = initialUserPreferences;
  }
  return current;
}

export function setUserPreferences(user_preferences) {
  ls.setItem(user_preferences_key, user_preferences);
}

export function deleteUserPreferences() {
  ls.removeItem(user_preferences_key);
}

//// USER LIBRARY
export function getUserLibrary() {
  return ls.getItem(user_library_key);
}

export function setUserLibrary(user_library) {
  ls.setItem(user_library_key, user_library);
}

export function deleteUserLibrary() {
  ls.removeItem(user_library_key);
}

//// CHECK ADMIN
export function checkAdmin() {
  const overview = getOverview();
  let isAdmin = overview && overview.isAdmin;
  return isAdmin;
}

//// CHECK BETA TESTER
export function checkBetaTester() {
  const overview = getOverview();
  let showSearch = overview && overview.isBetaTester;
  return showSearch;
}

//// CHECK ADMIN DEMO USER
export function checkAdminDemoUser() {
  const overview = getOverview();
  let adminDemo = overview && overview.isAdminDemoUser;
  return adminDemo;
}

//// CHECK COACH
export function checkCoach() {
  const overview = getOverview();
  let isAdmin = overview && (overview.isAdmin || overview.isCoach);
  return isAdmin;
}

//// CHECK SIMA ACCESS
export function checkSimaAccess() {
  const overview = getOverview();
  let hasSimaAccess = overview && (overview.isAdmin || overview.showSimaReview || overview.showSimaStaff);
  return hasSimaAccess;
}

//// CHECK SHOW STAFF SEARCH
export function checkShowStaffSearch() {
  const overview = getOverview();
  let showStaffSearch = overview && overview.showStaffSearch;
  return showStaffSearch;
}

//// CHECK SHOW SEARCH
export function checkShowSearch() {
  const overview = getOverview();
  let showSearch = overview && overview.showSearch;
  return showSearch;
}

//// CHECK MASQUERADE
export function checkMasquerade() {
  const overview = getOverview();
  let canMasquerade = overview && overview.roles.some(r => r.id === ENUMS.ROLES.MASQUERADER.ID);
  return canMasquerade;
}

//// CHECK MANAGER
export function checkManager() {
  const overview = getOverview();
  let isStaff = overview && overview.roles.some(r => r.id === ENUMS.ROLES.STAFF.ID);
  return isStaff;
}

//// CHECK MANAGE ORGANIZATIONS
export function checkManageOrganizations() {
  const overview = getOverview();
  let showOrgs = overview && overview.showOrganizations;
  return showOrgs;
}

//// CHECK FUNDRAISER
export function checkFundraiser() {
  const overview = getOverview();
  let isFundraiser = overview && overview.roles.some(r => r.id === ENUMS.ROLES.FUNDRAISER.ID);
  return isFundraiser;
}

//// USER PROFILE
export function loadUserProfile(user_id, success_cb, error_cb) {
  getRequest(`/users/${user_id}`, success_cb, null, error_cb);
}

export function modifyUserProfile(user_id, send_data, success_cb, error_cb) {
  putRequest(`/users/${user_id}`, send_data, success_cb, null, error_cb);
}

//// USER PROFILE RECORDS
export function loadUserRecords(success_cb, error_cb) {
  getRequest(`/users/records`, success_cb, null, error_cb);
}

//// USER CERTIFICATES
export function getUserCertificates(success_cb, error_cb) {
  getRequest(`/certificates`, success_cb, null, error_cb);
}

//// USER CERTIFICATES NOTIFICATION READ
export function markCertificateNotificationsRead(success_cb, error_cb) {
  putRequest(`/certificates/mark_notifications_read`, success_cb, null, error_cb);
}

//// USER DASHBOARD METRICS
export function loadDashboardMetrics(success_cb, error_cb) {
  getRequest(`/dashboard/metrics`, success_cb, null, error_cb);
}

//// LOGIN/LOGOUT
export function logoutUser() {
  try {
    let user_token = ls.getDigestToken();
    if (user_token) {
      putRequest(`/auth/user_token/${user_token}/revoke`, null, null, logoutFinalCB, null, true, true);
    } else {
      logoutFinalCB();
    }
  } catch (e) {
    // even if the above fails, delete all local data
    logoutFinalCB();
  }
}

function logoutFinalCB(skipRedirect) {
  deleteOverview();
  // TODO move these items to appropriate data objects
  ls.deleteAPIToken();
  ls.deleteDigestToken();
  ls.deleteUsername();
  ls.deleteMasqueradeInfo();
  ls.removeItem("enrollment_dashboard");
  ls.removeItem("contract_status");
  ls.removeItem("contract_last_checked");
  ls.removeItem("messages_last_checked");
  ls.addFlashMessage({ type: "success", message: "Logged out." });
  if (!skipRedirect) {
    ls.removeItem(redirect_url_key);
    updateURL("/");
  }
}

export function loadUserLogin(matchPath, path) {
  // If this user is logging out, call the logout routine
  if (matchPath === "/logout") {
    logoutUser();
  } else {
    // If this user was trying to get to a specific page that requires authentication, store that url for post login
    if (path !== "/login") {
      // Store the desired URL
      ls.setItem(redirect_url_key, path);
    }
    // Now that we have stored the desired URL (if present), set the path to "/login", but don't reload the page
    updateURLWithoutLoad("/login");
  }
}

export function userLogin(formData, loginFailedCB) {
  const failCallback = loginFailedCB ? loginFailedCB : defaultLoginFailedCB;
  // Trim any excess spaces from either side of the username (these should never exist)
  let username = formData.username.trim();
  ls.setUsername(username);
  // TODO find a different way to send the password
  let hackedFormParams =
    "username=" + encodeURI(username) + "&password=" + encodeURI(btoa(formData.password)) + "&remember_me=" + encodeURI(formData.remember_me);
  // Special call that skips our retry and preflight logic, needed for login
  postRequest(`/auth/user_token/init`, hackedFormParams, loginUpdateCB, null, failCallback, true, true);
}

function loginUpdateCB(response) {
  let digest_token = response.headers.authorization;
  ls.setDigestToken(digest_token);
  // TODO add masquerade_user_id
  let hackedFormParams = "username=" + encodeURI(ls.getUsername()) + "&user_token=" + encodeURI(digest_token);
  // Special call that skips our retry and preflight logic, needed for login
  postRequest(`/auth/api_token/init`, hackedFormParams, redirectCB, null, defaultLoginFailedCB, true, true);
}

function redirectCB(response) {
  let api_token = response.headers.authorization;
  ls.setAPIToken(api_token);
  const redirectURL = ls.getItem(redirect_url_key) || "/";

  // Load the User's Overview Data and then redirect to desired URL or home
  loadOverview(() => {
    updateURL(redirectURL);
  });
}

function defaultLoginFailedCB(response) {
  console.log(response);
}

export function parseDigest(digest) {
  // Decode Base64 digest
  try {
    let decoded_digest = window.atob(digest);
    let key_pairs = decoded_digest.split(";");
    let digest_object = {};
    for (let i = 0; i < key_pairs.length; i++) {
      let key_pair = key_pairs[i].split(":");
      digest_object[key_pair[0]] = key_pair[1];
    }

    return digest_object;
  } catch (e) {
    // If decoding failed for any reason (improper Base64 string or bad data), just return an empty object
    return {};
  }
}

//// USER REGISTRATION
export function loadUserRegistration(reset_digest, setState) {
  // If this user was already logged in, log them out and return to this URL
  let user_token = ls.getDigestToken();
  if (user_token) {
    let skipRedirect = true;
    logoutFinalCB(skipRedirect);
    updateURL("/register/" + reset_digest);
  }
  if (reset_digest.length === 25) {
    // CANVAS Legacy Catch, canvas confirmation codes are always 25 characters long
    putRequest(
      `/auth/legacy_token/${reset_digest}`,
      null,
      data => {
        setState({ username: data.emailAddress, isLoading: false });
      },
      null,
      error => {
        setState({ error: error.response.data.error });
      },
      true,
      true
    );
  } else {
    let digest_object = parseDigest(reset_digest);
    // Special call that skips our retry and preflight logic
    getRequest(
      `/auth/users/${digest_object.userId}/token/${digest_object.token}`,
      data => {
        setState({ username: data.emailAddress, isLoading: false });
      },
      null,
      error => {
        setState({ error: error.response.data.error });
      },
      true,
      true
    );
  }
}

export function userRegister(reset_digest, data, setState) {
  let digest_object = parseDigest(reset_digest);
  putRequest(
    `/auth/users/${digest_object.userId}/password_reset/${digest_object.token}`,
    data,
    data => {
      ls.addFlashMessage({ type: "success", message: "Registration complete! Please sign in below." });
      updateURL("/");
    },
    null,
    error => {
      setState({ error: error.response.data.error });
    },
    true,
    true
  );
}

//// USER PASSWORD RESET
export function loadPasswordReset(reset_digest, setState) {
  let digest_object = parseDigest(reset_digest);
  // If this user was already logged in, log them out and return to this URL
  let user_token = ls.getDigestToken();
  if (user_token) {
    let skipRedirect = true;
    logoutFinalCB(skipRedirect);
    updateURL("/password_reset/" + reset_digest);
  }
  // Special call that skips our retry and preflight logic
  getRequest(
    `/auth/users/${digest_object.userId}/token/${digest_object.token}`,
    data => {
      setState({ username: data.emailAddress, isLoading: false });
    },
    null,
    error => {
      setState({ error: error.response.data.error });
    },
    true,
    true
  );
}

export function passwordReset(reset_digest, data, setState) {
  let digest_object = parseDigest(reset_digest);
  putRequest(
    `/auth/users/${digest_object.userId}/password_reset/${digest_object.token}`,
    data,
    data => {
      ls.addFlashMessage({ type: "success", message: "Password has been reset! Please sign in below." });
      updateURL("/");
    },
    null,
    error => {
      setState({ error: error.response.data.error });
    },
    true,
    true
  );
}

export function updatePassword(formData, success_cb, final_cb, failure_cb) {
  let data = {
    emailAddress: ls.getUsername(),
    oldPassword: formData.old_password,
    newPassword: formData.new_password,
    newPasswordConfirm: formData.confirm_password
  };

  putRequest(`/auth/update_password`, data, success_cb, final_cb, failure_cb);
}

export function forgotPassword(email, success_cb, error_cb) {
  putRequest(`/auth/forgot_password?email=${email}`, null, success_cb, null, error_cb, false, true);
}

export function uploadNewAvatar(user_id, avatarRawData, avatarOriginalRawData, avatarSmallRawData, success_cb, error_cb) {
  // The result needs to be cleaned before it can be sent (removes the initial "data:*/*;base64,")
  // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
  let result = avatarRawData.split(",")[1];
  let resultOriginal = avatarOriginalRawData.split(",")[1];
  let resultSmall = avatarSmallRawData.split(",")[1];
  let formData = {
    id: user_id,
    base64EncodedAvatar: result,
    base64EncodedAvatarOriginal: resultOriginal,
    base64EncodedAvatarSmall: resultSmall
  };
  modifyUserProfile(user_id, formData, success_cb, error_cb);
}

export function uploadNewSignature(user_id, signatureRawData, signatureOriginalRawData, success_cb, error_cb) {
  // The result needs to be cleaned before it can be sent (removes the initial "data:*/*;base64,")
  // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
  let result = signatureRawData.split(",")[1];
  let resultOriginal = signatureOriginalRawData ? signatureOriginalRawData.split(",")[1] : null;
  let formData = {
    id: user_id,
    base64EncodedSignature: result,
    base64EncodedSignatureOriginal: resultOriginal
  };
  modifyUserProfile(user_id, formData, success_cb, error_cb);
}
