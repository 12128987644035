import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import { List, ListItemButton, ListItemIcon, ListItemText, Badge, Collapse, Portal, ClickAwayListener } from "@mui/material";
import { colors, Glower } from "bild-ui";

const useStyles = makeStyles({
  listItem: props => ({
    whiteSpace: "nowrap",
    borderLeft: "5px solid transparent",
    height: "47px",
    "& *": { color: colors.menuGray, marginLeft: "-1px" },
    "&:hover *": { color: "#FFF" },
    "& svg": { width: "17px", height: "17px", marginLeft: "5px" },
    backgroundColor: props.header.active ? "#3E434A" : (props.header.backgroundColor ? props.header.backgroundColor : "transparent"),
  }),
  listItemIconWrapper: { minWidth: "44px", fontSize: "1rem" },
  listItemIcon: props => ({ color: props.header.iconColor ? `${props.header.iconColor} !important` : colors.menuGray }),
  listItemText: { "& *": { fontWeight: "bold" } },
  expandButtonIcon: { fontSize: "0.6rem", paddingLeft: "0.65rem" },
  subList: { padding: "0", borderLeft: "5px solid transparent", backgroundColor: "#2A3137", zIndex: "1200" },
  subListItem: {
    paddingLeft: "1.75rem",
    "& *": { color: "#AAA", fontWeight: "bold" },
    "&:hover *": { color: "#FFF" }
  },
  badge: {
    color: "#FFF !important",
    "& *": { color: "#FFF" }
  }
});

function LeftMenuItem({ header, mini, ...props }) {
  const cls = useStyles({ header });
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(header.active && !mini);
  const ref = useRef(null);
  const [oldMini, setOldMini] = useState(mini);
  if (mini !== oldMini) {
    setOpen(false);
    setOldMini(mini);
  }

  const hasLinks = header.links && header.links.length > 0;
  const linkComponents = [];
  let linkPosition = {};
  if (hasLinks) {
    for (let i = 0; i < header.links.length; i++) {
      const l = header.links[i];
      linkComponents.push(
        <ListItemButton
          component="a"
          href={l.href}
          target={l.target}
          className={cls.subListItem}
          style={{
            borderColor: header.active || hovered || open ? header.accent : "transparent",
            backgroundColor: header.active ? "#3E434A" : "transparent"
          }}
          disableRipple
          key={i}
        >
          <ListItemText className={cls.subListItemText}>{ReactHtmlParser(l.title)}</ListItemText>
          <Badge max={999} badgeContent={l.badge ? l.badge : null} color={l.badgeClass ? l.badgeClass : "secondary"} overlap="rectangular">
            <span />
          </Badge>
        </ListItemButton>
      );
    }

    if (mini && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      linkPosition = { position: "absolute", left: rect.right, top: rect.top };
    }
  }
  const hideBadge = !(header.badge && header.badge > 0);

  return (
    <div ref={ref}>
      <Glower glowOn={header.glow} color={header.glowColor ? header.glowColor : colors.white}>
        <ListItemButton
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={hasLinks ? () => setOpen(!open) : null}
          className={cls.listItem}
          component={hasLinks ? null : "a"}
          href={hasLinks ? null : header.href}
          style={{
            borderColor: header.active || hovered || open ? header.accent : "transparent",
            
            width: "100%"
          }}
          disableRipple
          {...props}
        >
          <ListItemIcon className={cls.listItemIconWrapper}>
            <Badge invisible={!mini || hideBadge} color={header.badgeClass ? header.badgeClass : "secondary"} variant="dot" overlap="rectangular">
              <i className={`${cls.listItemIcon} ${header.icon}`} />
            </Badge>
          </ListItemIcon>

          <ListItemText className={cls.listItemText}>
            {ReactHtmlParser(header.title)}
            {hasLinks && (<i className={`fal fa-chevron-${open ? "up" : "down"} fa-xs ${cls.expandButtonIcon}`} />)}
          </ListItemText>

          <Badge
            max={999}
            className={cls.badge}
            badgeContent={header.badge ? header.badge : null}
            invisible={mini || hideBadge}
            color={header.badgeClass ? header.badgeClass : "secondary"}
            overlap="rectangular"
          >
            <span className={cls.badge} />
          </Badge>
        </ListItemButton>
      </Glower>

      {hasLinks && (
        <Portal disablePortal={!mini} container={document.getElementById("root")}>
          <Collapse in={open} timeout={mini ? 0 : "auto"} style={{ ...linkPosition }}>
            <ClickAwayListener
              onClickAway={e => {
                const { x, y } = e;
                const { top, right, bottom, left } = ref.current.getBoundingClientRect();
                if (x < left || x > right || y < top || y > bottom) setOpen(false);
              }}
            >
              <List className={cls.subList} style={{ borderColor: header.accent }} disablePadding>
                {linkComponents}
              </List>
            </ClickAwayListener>
          </Collapse>
        </Portal>
      )}
    </div>
  );
}

export default LeftMenuItem;
