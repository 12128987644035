import React from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { BTypography, colors, CircleProgress, Blink, NormalButton } from "bild-ui";
import { daysElapsed, getFirstName } from "bild-utils";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { padding: "0.5rem" },
  message: { padding: "1rem", textAlign: "center" },
  section: { padding: "0.5rem" },
  item: { border: "1px solid #d3d4d5", borderRadius: "3", width: "100%" },
  head: {
    padding: "0.75rem",
    background: colors.veryLightGray,
    height: "3rem",
    lineHeight: "2",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: { color: colors.antiochPurple },
  equipHead: {
    padding: "0.75rem",
    background: colors.veryLightGray,
    height: "3rem",
    lineHeight: "2",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  equipTitle: { color: colors.bildBlue },
  body: { padding: "0.75rem", flex: "0 1 auto" },
  row: { paddingBottom: "0.5rem" },
  leftCol: { minWidth: "1.5rem", display: "flex" },
  rightCol: { paddingLeft: "0.25rem" },
  link: { color: colors.blue }
});

function DashboardMetrics({ userOverview, metrics }) {
  const cls = useStyles();
  const u = metrics.user;
  const st = metrics.studentMetrics;
  const lm = metrics.localMentorMetrics;
  const af = metrics.associateFacultyMetrics;
  const afc = metrics.coachMetrics;

  const today = Date.parse(new Date().toISOString().slice(0, 10));
  let enrollmentStartDiff, lastSelf, lastValidated, loginDiff, loginIcon, selfIcon, validationIcon;
  if (st) {
    enrollmentStartDiff = daysElapsed(Date.parse(st.studentEnrollmentStartDate), today);
    lastSelf = st.lastSelfAssessmentDate ? daysElapsed(Date.parse(st.lastSelfAssessmentDate), today) : null;
    lastValidated = st.lastValidatedAssessmentDate ? daysElapsed(Date.parse(st.lastValidatedAssessmentDate), today) : null;
    loginDiff = st.loginDifference;
    if (!loginDiff) {
      loginDiff = daysElapsed(Date.parse(st.previousLastLogin), today);
    }
    loginIcon = <i className={`fas fa-${(loginDiff !== null ? loginDiff : enrollmentStartDiff) <= 30 ? "thumbs-up" : "exclamation"}`} />;
    selfIcon = <i className={`fas fa-${(lastSelf !== null ? lastSelf : enrollmentStartDiff) <= 40 ? "thumbs-up" : "exclamation"}`} />;
    validationIcon = <i className={`fas fa-${lastValidated <= 80 ? "thumbs-up" : "exclamation"}`} />;
  }

  function row(l, r) {
    return (
      <Grid container item xs={12} className={cls.row} justifyContent="flex-start" alignItems="flex-start">
        <Grid item className={cls.leftCol}>
          {l}
        </Grid>
        <Grid item xs className={cls.rightCol}>
          {r}
        </Grid>
      </Grid>
    );
  }

  function message(p, v) {
    let messages = [
      `Your last login was on ${v}. We encourage you to log in monthly.`,
      `Your last login was on ${v}. We encourage you to log in monthly.`,
      `Your last login was on ${v}. We encourage you to log in monthly.`,
      `The last time you submitted an assessment was ${v}. Keep up the good work!`,
      `The last time you submitted an assessment was ${v}. Looks like it's time to do another!`,
      `The last time you submitted an assessment was ${v}. You're due to submit another.`,
      `Your most recently validated competency was on ${v}. Keep up the good work!`,
      `Your most recently validated competency was on ${v}. Looks like it's time to do another!`,
      `Your most recently validated competency was on ${v}. Looks like it's time to do another!`,
      `We encourage you to log in monthly.`,
      `We encourage you to log in monthly.`,
      `We encourage you to log in monthly.`,
      `You can start submitting work you have ready for review.`,
      `Looks like it's time to submit an assessment.`,
      `You're due to submit an assessment.`
    ];

    return messages[p];
  }

  return (
    <Grid container className={cls.container}>
      {st && st.studentMessages && (
        <Grid item xs={12} className={cls.message}>
          {ReactHtmlParser(st.studentMessages)}
        </Grid>
      )}
      {st && (
        <Grid container item sm={6} xs={12} className={cls.section}>
          <Grid container item className={cls.item} direction="column">
            <div className={cls.head}>
              <BTypography variant="h6" className={cls.title}>Antioch School Submissions:</BTypography>
            </div>
            <Grid container item xs={12} className={cls.body}>
              {st.previousLastLogin &&
                st.previousLastLogin !== "January 1, 1970" &&
                row(
                  <CircleProgress level="warning-danger" content={loginIcon} criteria={loginDiff <= 30} criteria1={loginDiff <= 90} />,
                  <BTypography>{message(loginDiff <= 90 ? (loginDiff <= 30 ? 0 : 1) : 2, st.previousLastLogin)}</BTypography>
                )}
              {(!st.previousLastLogin || (st.previousLastLogin && st.previousLastLogin === "January 1, 1970")) &&
                row(
                  <CircleProgress
                    level="warning-danger"
                    content={loginIcon}
                    criteria={enrollmentStartDiff <= 30}
                    criteria1={enrollmentStartDiff <= 90}
                  />,
                  <BTypography>
                    {message(enrollmentStartDiff <= 90 ? (enrollmentStartDiff <= 30 ? 9 : 10) : 11, st.studentEnrollmentStartDate)}
                  </BTypography>
                )}
              {lastSelf !== null &&
                row(
                  <CircleProgress level="warning-danger" content={selfIcon} criteria={lastSelf <= 40} criteria1={lastSelf <= 80} />,
                  <BTypography>{message(lastSelf <= 80 ? (lastSelf <= 40 ? 3 : 4) : 5, st.lastSelfAssessmentDate)}</BTypography>
                )}
              {lastSelf === null &&
                row(
                  <CircleProgress
                    level="warning-danger"
                    content={loginIcon}
                    criteria={enrollmentStartDiff <= 40}
                    criteria1={enrollmentStartDiff <= 80}
                  />,
                  <BTypography>
                    {message(enrollmentStartDiff <= 80 ? (enrollmentStartDiff <= 40 ? 12 : 13) : 14, st.studentEnrollmentStartDate)}
                  </BTypography>
                )}
              {lastValidated !== null &&
                row(
                  <CircleProgress level="warning-danger" content={validationIcon} criteria={lastValidated <= 80} criteria1={lastValidated <= 120} />,
                  <BTypography>{message(lastValidated <= 120 ? (lastValidated <= 80 ? 6 : 7) : 8, st.lastValidatedAssessmentDate)}</BTypography>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {lm && (
        <Grid container item sm={6} xs={12} className={cls.section}>
          <Grid container item className={cls.item} direction="column">
            <div className={cls.head}>
              <BTypography variant="h6" className={cls.title}>Initial Reviews:</BTypography>
            </div>
            <Grid container item xs={12} className={cls.body}>
              {row(
                <CircleProgress content={lm.initialAssessmentsOverWarning} level="warning" criteria={lm.initialAssessmentsOverWarning <= 0} />,
                <BTypography>Assessments waiting between 10-14 days</BTypography>
              )}
              {row(
                <CircleProgress content={lm.initialAssessmentsOverDanger} level="danger" criteria={lm.initialAssessmentsOverDanger <= 0} />,
                <BTypography>Assessments waiting over 15 days</BTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {af && (
        <Grid container item sm={6} xs={12} className={cls.section}>
          <Grid container item className={cls.item} direction="column">
            <div className={cls.head}>
              <BTypography variant="h6" className={cls.title}>Validation Reviews:</BTypography>
            </div>
            <Grid container item xs={12} className={cls.body}>
              {row(
                <CircleProgress content={af.validationAssessmentsOverWarning} level="warning" criteria={af.validationAssessmentsOverWarning <= 0} />,
                <BTypography>Assessments waiting between 20-24 days</BTypography>
              )}
              {row(
                <CircleProgress content={af.validationAssessmentsOverDanger} level="danger" criteria={af.validationAssessmentsOverDanger <= 0} />,
                <Blink dst={"delinquent_review"} user_id={u.id} name={u.name} className={`${cls.link} default-button`}>
                  <BTypography>Assessments waiting over 25 days</BTypography>
                </Blink>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {afc && (
        <Grid container item sm={6} xs={12} className={cls.section}>
          <Grid container item className={cls.item} direction="column">
            <div className={cls.head}>
              <BTypography variant="h6" className={cls.title}>Coach Reviews:</BTypography>
            </div>
            <Grid container item xs={12} className={cls.body}>
              {row(
                <CircleProgress content={afc.coachReviewOverWarning} level="warning" criteria={afc.coachReviewOverWarning <= 0} />,
                <BTypography>Assessments waiting between 2-4 days</BTypography>
              )}
              {row(
                <CircleProgress content={afc.coachReviewOverDanger} level="danger" criteria={afc.coachReviewOverDanger <= 0} />,
                <BTypography>Assessments waiting over 5 days</BTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {userOverview && userOverview.showEquipReview && (
        <Grid container item sm={6} xs={12} className={cls.section}>
          <Grid container item className={cls.item} direction="column">
            <div className={cls.equipHead}>
              <BTypography variant="h6" className={cls.equipTitle}>Equip Reviews:</BTypography>
            </div>
            <Grid container item xs={12} className={cls.body}>
              <NormalButton
                component={Blink}
                dst={"equip-review"}
                variant={"simple"}
              >
                {row(
                  <CircleProgress content={userOverview.equipReviewCount} level="danger" criteria={userOverview.equipReviewCount <= 10} />,
                  <BTypography>Assessments waiting &nbsp; <i className={userOverview.equipReviewCount > 0 ? "far fa-chevron-right" : ""} /></BTypography>
                )}
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default DashboardMetrics;
