import React, { useState, useRef } from "react";
import { ENUMS, userData } from "bild-data";
import { makeStyles } from "@mui/styles";
import { getUsername } from "bild-data/core/localStorage";
import { Blink, colors, NormalButton, BTypography, UserAvatar, PopTip } from "bild-ui";
import { Grid, Badge, AppBar, Popper, Button, List, ListItemButton, ListItemText, Divider, ClickAwayListener, Switch } from "@mui/material";
import ExpandingFieldSearchButton from "presentational/input/expandingFieldSearchButton.js";
import SearchHistoryPopper from "./searchHistoryPopper.js";
import SupportTicketDialog from "presentational/dialogs/supportTicketDialog.js";
import ChangeHandbookDialog from "presentational/dialogs/changeHandbookDialog.js";
import { refreshPage } from "bild-utils/window.js";

const useStyles = makeStyles({
  container: { backgroundColor: "#2A3137", boxShadow: "none", height: "50px", borderBottom: "1px solid white" },
  grid: { height: "100%", width: "100%" },
  menuItemsContainer: { height: "100%" },
  menuHeader: { height: "48px", width: "250px" },
  menuHeaderMini: { height: "48px", width: "60px" },
  logoContainer: { height: "34px", marginLeft: "18px" },
  logo: { height: "34px", width: "auto" },
  menuButton: {
    height: "100%",
    width: "48px",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)", textDecoration: "none" },
    cursor: "pointer"
  },
  menuButtonIcon: {
    paddingTop: "4px",
    color: "white"
  },
  nameProfilePic: {
    "&:hover": {
      filter: "brightness(0.8)"
    }
  },
  supportIcon: { color: colors.white },
  messageIcon: { backgroundColor: colors.red },
  searchIcon: { color: colors.white },
  popper: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    zIndex: "1000"
  },
  popperList: {
    backgroundColor: "white"
  },
  popperArrow: {
    display: "inline-block",
    backgroundColor: "white",
    width: "9px",
    height: "9px",
    transform: "rotate(45deg)",
    position: "absolute"
  },
  demoSwitchWrapper: { textAlign: "center" },
  systemRecentActivity: { height: "100%" },
  systemRecentActivityIcon: { color: colors.white },
  searchButtonContainer: { height: "100%" },
  active: { backgroundColor: colors.gray, marginTop: "-1px" }
});

function TopMenu({ path, user, canStaffSearch, canSearch, canMasquerade, isAdmin, isBetaTester, isDemoAccount, onOpenLeftMenu, onToggleMini, mini, mobile, userOverview, className, ...props }) {
  const cls = useStyles();
  const userPrefs = userData.getUserPreferences() || {};
  const [state, setState] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [demoMode, setDemoMode] = useState(userPrefs ? userPrefs.demoMode : false);
  const searchFieldRef = useRef(null);
  const supportButtonRef = useRef(null);
  const userButtonRef = useRef(null);
  const adminButtonRef = useRef(null);
  const username = getUsername();

  const messageCount = userOverview && userOverview.messagesCount ? userOverview.messagesCount : 0;
  const certificateBadge = userOverview && userOverview.notifyCertificateCount ? userOverview.notifyCertificateCount : 0;

  function toggleDemoMode() {
    const _prefs = userData.getUserPreferences() || {};
    _prefs.demoMode = !_prefs.demoMode;
    userData.setUserPreferences(_prefs);
    setDemoMode(_prefs.demoMode);
    refreshPage();
  }

  function _clickUserButton(e) {
    setState({ anchorEl: userButtonRef.current, userMenuOpen: true });
  }

  function _clickSupportButton(e) {
    setState({ anchorEl: supportButtonRef.current, supportMenuOpen: true });
  }

  function _clickTicketButton() {
    setState({ ticketModalOpen: true });
  }

  function _clickHandbookButton() {
    setState({ changeHandbookOpen: true });
  }

  function _clickAdminButton(e) {
    setState({ anchorEl: adminButtonRef.current, adminMenuOpen: true });
  }

  function _closeMenu() {
    setState({});
  }

  // TODO: this should be set by the backend, not all students should see the payments page
  let enrollment_id = userOverview && userOverview.enrollments && userOverview.enrollments[0] ? userOverview.enrollments[0].id : false;

  let userLetter = "";
  userLetter = user && user.name ? user.name[0] : "X";

  const userMenuItems = (
    <React.Fragment>
      <List className={cls.popperList} disablePadding>
        <Grid container item component={Blink} dst={"profile"}>
          <ListItemButton>
            <ListItemText>Profile</ListItemText>
          </ListItemButton>
        </Grid>
        {enrollment_id && (
          <>
            <Grid container item component={Blink} dst={"enrollment-statement"} enrollment_id={enrollment_id}>
              <ListItemButton>
                <ListItemText>Payments</ListItemText>
              </ListItemButton>
            </Grid>
            <Grid container item component={Blink} dst={"profile-records"}>
              <ListItemButton>
                <ListItemText>Records</ListItemText>
              </ListItemButton>
            </Grid>
          </>
        )}
        {userOverview && userOverview.equipRoles && userOverview.equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.USER.ID) && (
          <Grid container item component={Blink} dst={"user-certificates"}>
            <ListItemButton>
              <Badge invisible={certificateBadge ? false : true} color={"secondary"} variant="dot" overlap="rectangular">
                <ListItemText>Certificates</ListItemText>
              </Badge>
            </ListItemButton>
          </Grid>
        )}
        {isDemoAccount && (
          <Grid container item>
            <ListItemButton>
              <ListItemText className={cls.demoSwitchWrapper}>
                <PopTip text={`Demo Mode ${demoMode ? "ON" : "OFF"}`}>
                  <Switch
                    checked={demoMode}
                    onChange={toggleDemoMode}
                    color="warning"
                  />
                </PopTip>
              </ListItemText>
            </ListItemButton>
          </Grid>
        )}
        <Divider />
        <Grid container item component={Blink} dst={"logout"}>
          <ListItemButton>
            <ListItemText>Log Out</ListItemText>
          </ListItemButton>
        </Grid>
      </List>
    </React.Fragment>
  );
  const supportMenuItems = (
    <List className={cls.popperList} disablePadding>
      <Grid container item component={Blink} href="https://intercom.help/bild-international" target="_blank">
        <ListItemButton>
          <ListItemText>Help Center</ListItemText>
        </ListItemButton>
      </Grid>
      <ListItemButton onClick={_clickTicketButton}>
        <ListItemText>Submit a Support Request</ListItemText>
      </ListItemButton>
    </List>
  );
  const adminMenuItems = (
    <List className={cls.popperList} disablePadding>
      <Grid container item component={Blink} dst="system-recent-activity">
        <ListItemButton>
          <ListItemText><i className="fal fa-tachometer-alt-fast"/> &nbsp; System Activity</ListItemText>
        </ListItemButton>
      </Grid>
      <Grid container item component={Blink} dst="system-world-map">
        <ListItemButton>
          <ListItemText><i className="fal fa-map"/> &nbsp; World Map</ListItemText>
        </ListItemButton>
      </Grid>
      <Grid container item component={Blink} dst="system-beta-tester-leaderboard">
        <ListItemButton>
          <ListItemText><i className="fal fa-trophy-alt"/> &nbsp; Leaderboard</ListItemText>
        </ListItemButton>
      </Grid>
    </List>
  );

  let popperChildren = state.userMenuOpen ? userMenuItems : ( state.adminMenuOpen ? adminMenuItems : supportMenuItems);
  const arrowPosition = {};
  if (state.anchorEl) {
    const rect = state.anchorEl.getBoundingClientRect();
    arrowPosition.x = rect.x + rect.width / 2 - 5;
    arrowPosition.y = rect.y + rect.height - 5;
  }

  return (
    <AppBar position="fixed" className={[className, cls.container].join(" ")} {...props}>
      <Grid container justifyContent="space-between" alignItems="center" className={cls.grid}>
        {!mobile && (
          <Grid
            item
            container
            justifyContent={mini ? "center" : "space-between"}
            alignItems="center"
            className={mini ? cls.menuHeaderMini : cls.menuHeader}
          >
            {!mini && (
              <Grid item className={cls.logoContainer} component={Blink}>
                <img id="bc2-logo-l" className={cls.logo} src="/bild_menu_logo.png" alt="bc2-logo-l" />
              </Grid>
            )}

            <Grid item container justifyContent="center" alignItems="center" className={cls.menuButton} onClick={onToggleMini}>
              <i className={`fal fa-bars fa-lg ${cls.menuButtonIcon}`} />
            </Grid>
          </Grid>
        )}

        {mobile && (
          <Grid item container justifyContent="center" alignItems="center" className={cls.menuButton} onClick={onOpenLeftMenu}>
            <i className={`fal fa-bars fa-lg ${cls.menuButtonIcon}`} />
          </Grid>
        )}

        <Grid xs item container justifyContent="flex-end" alignItems="center" className={cls.menuItemsContainer}>
          {userOverview && userOverview.showChangeHandbook && (
            <NormalButton className="default-submit-button" onClick={_clickHandbookButton}>
              <BTypography variant="none">
                {mobile && <i className="fad fa-exchange"/>}
                {!mobile && <>Switch to the New Handbook</>}
              </BTypography>
            </NormalButton>
          )}

          {canStaffSearch && !canSearch && (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={`${cls.menuButton} ${path && path.substring(0, 13) === "/staff/search" ? cls.active : ""}`}
              component={Blink}
              dst="staff-search"
            >
              <i className={`fal fa-search fa-lg ${cls.searchIcon}`} />
            </Grid>
          )}

          {canSearch && (
            <Grid item className={cls.searchButtonContainer}>
              <ExpandingFieldSearchButton inputRef={searchFieldRef} onChange={setSearchTerm}>
                <SearchHistoryPopper searchTerm={searchTerm} anchorEl={searchFieldRef.current} canMasquerade={canMasquerade} isAdmin={isAdmin} />
              </ExpandingFieldSearchButton>
            </Grid>
          )}

          {userOverview && (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={`${cls.menuButton} ${path && path.substring(0, 9) === "/messages" ? cls.active : ""}`}
              component={Blink}
              dst="messages"
            >
              <Badge
                classes={{ badge: cls.messageIcon }}
                variant="dot"
                invisible={messageCount ? false : true}
                content={messageCount ? messageCount : 0}
                overlap="rectangular"
              >
                <i className={`fal fa-comments-alt fa-lg ${cls.supportIcon}`} />
              </Badge>
            </Grid>
          )}

          <Grid item container justifyContent="center" alignItems="center" className={cls.menuButton} onClick={_clickSupportButton} ref={supportButtonRef}>
            <i className={`fal fa-question-circle fa-lg ${cls.supportIcon}`} />
          </Grid>

          {(canSearch || isBetaTester) && (
            <Grid
              item
              className={`${cls.menuButton} ${path && (path.substring(0, 31) === "/reports/system_recent_activity" || path.substring(0, 10) === "/world_map") ? cls.active : ""}`}
              onClick={_clickAdminButton}
              ref={adminButtonRef}
            >
              <Grid container item alignItems="center" justifyContent="center" className={cls.systemRecentActivity}>
                <Grid item>
                  <i className={`${cls.systemRecentActivityIcon} fas fa-globe-americas`} />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item container justifyContent="center" alignItems="center" className={cls.menuButton} onClick={_clickUserButton} ref={userButtonRef}>
            <Grid item className={cls.nameProfilePic}>
              <Badge
                invisible={certificateBadge ? false : true}
                color={"secondary"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                badgeContent={<i className={"fas fa-file-certificate"} />}
                overlap="rectangular"
              >
                <UserAvatar size={40} name={user ? user.name : null} hideName src={user ? user.avatarURL : null} srcSize="small" badgePos="left" badgeSrc={user ? user.badgeURL : null} />
              </Badge>
            </Grid>
          </Grid>

          <div
            className={cls.popperArrow}
            style={{ visibility: Boolean(state.anchorEl) ? "visible" : "hidden", left: arrowPosition.x, top: arrowPosition.y }}
          />

          <Popper anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} className={cls.popper}>
            <ClickAwayListener onClickAway={_closeMenu}>
              <div>{popperChildren}</div>
            </ClickAwayListener>
          </Popper>
        </Grid>
      </Grid>
      <SupportTicketDialog open={state.ticketModalOpen ? true : false} closeDialog={_closeMenu} username={username} />
      <ChangeHandbookDialog open={state.changeHandbookOpen ? true : false} closeDialog={_closeMenu} username={username} />
    </AppBar>
  );
}

export default TopMenu;
