import React, { useState, useEffect } from "react";
import { colors, List, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Input } from "@mui/material";

const useStyles = makeStyles({
  footer: props => ({
    padding: "1rem",
    backgroundColor: props.backgroundColor ? props.backgroundColor : colors.white
  }),
  button: {
    padding: "0.5rem 1rem"
  },
  startButton: {
    marginRight: "0.5rem"
  },
  endButton: {
    marginLeft: "0.5rem"
  },
  scope: {},
  pageInput: {
    maxWidth: "3rem",
    border: `1px solid ${colors.veryLightGray}`,
    borderRadius: "0.2rem",
  },
  totalItems: {
    textAlign: "center",
    padding: "0.25rem"
  },
  totalPageText: {
    color: colors.bildGray,
  },
  endButtonsWrapper: { textAlign: "right" },
});

function PaginationList({
  perPage,
  filter,
  title,
  details,
  headings,
  items,
  itemType,
  emptyMessage,
  spacing,
  backgroundColor,
  rowColor,
  evenRowColor,
  itemPadding,
  fixedHeight
}) {
  const cls = useStyles({ backgroundColor });
  const [_items, _setItems] = useState([]);
  const lastPage = Math.ceil(items.length / perPage);
  const [currentPage, setCurrentPage] = useState(0);
  const showEndButtons = lastPage >= 3 ? true : false;

  useEffect(() => {
    // Always set page to 0 if filter is active
    if (filter && filter.length > 1) {
      setCurrentPage(0);
    }
  }, [filter]);

  useEffect(() => {
    // Always set page to 0 if items change and there are fewer items than the current page
    if (items.length < currentPage * perPage) {
      setCurrentPage(0);
    }
  }, [items]);

  useEffect(() => {
    if (perPage) {
      let startPos = currentPage ? currentPage * perPage : 0;
      let endPos = startPos + perPage <= items.length ? startPos + perPage : items.length;
      _setItems(items.slice(startPos, endPos));
    } else {
      _setItems(items);
    }
  }, [items, currentPage, perPage]);

  function _setCurrentPage(nextPage) {
    if (nextPage >= lastPage) {
      // do nothing, this is over the limit
    } else if (nextPage < 0) {
      // do nothing, this is under the minimum
    } else {
      setCurrentPage(nextPage);
    }
  }

  function _setCurrentPageFromField(e) {
    let sanitizedNextPage = Number(e.target.value) - 1;
    _setCurrentPage(sanitizedNextPage);
  }

  if (_items) {
    return (
      <Grid container className={cls.wrapper}>
        <Grid item xs={12}>
          <List
            title={title}
            details={details}
            headings={headings}
            items={_items}
            emptyMessage={emptyMessage}
            spacing={spacing}
            backgroundColor={backgroundColor}
            rowColor={rowColor}
            evenRowColor={evenRowColor}
            itemPadding={itemPadding}
            fixedHeight={fixedHeight}
          />
        </Grid>
        {lastPage > 1 && (
          <Grid container item xs={12} justifyContent="space-between" alignItems="flex-start" className={cls.footer}>
            <Grid item xs={4}>
              {showEndButtons && (
                <NormalButton
                  onClick={() => {
                    _setCurrentPage(0);
                  }}
                  variant="secondary"
                  borderColor={colors.darkerGray}
                  hoverColor={colors.darkerGray}
                  className={`${cls.button} ${cls.startButton}`}
                  disabled={currentPage < 1}
                >
                  <i className="fas fa-step-backward fa-fw" />
                </NormalButton>
              )}
              <NormalButton
                onClick={() => {
                  _setCurrentPage(currentPage - 1);
                }}
                variant="primary"
                borderColor={colors.darkerGray}
                hoverColor={colors.darkerGray}
                className={cls.button}
                disabled={currentPage < 1}
              >
                <i className="fas fa-play fa-rotate-180 fa-fw" />
              </NormalButton>
            </Grid>
            <Grid item className={cls.scope} xs={4}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <BTypography variant="h6" className={cls.totalPageText}>
                    Page &nbsp;
                  </BTypography>
                </Grid>
                <Grid item>
                  <Input
                    disableUnderline
                    value={currentPage + 1}
                    onChange={_setCurrentPageFromField}
                    type="number"
                    className={cls.pageInput}
                    inputProps={{min: 0, style: { textAlign: 'right', paddingRight: "0.25rem" }}}
                  />
                </Grid>
                <Grid item>
                  <BTypography variant="h6" className={cls.totalPageText}>
                    &nbsp; of &nbsp;
                    {lastPage.toLocaleString()}
                  </BTypography>
                </Grid>
                <Grid item xs={12} className={cls.totalItems}>
                  <BTypography variant="h6">
                    {items.length.toLocaleString()} {itemType ? itemType : "Rows"}
                  </BTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={cls.endButtonsWrapper} xs={4}>
              <NormalButton
                onClick={() => {
                  _setCurrentPage(currentPage + 1);
                }}
                variant="secondary"
                borderColor={colors.darkerGray}
                hoverColor={colors.darkerGray}
                className={cls.button}
                disabled={currentPage + 1 >= lastPage}
              >
                <i className="fas fa-play fa-fw" />
              </NormalButton>
              {showEndButtons && (
                <NormalButton
                  onClick={() => {
                    _setCurrentPage(lastPage - 1);
                  }}
                  variant="secondary"
                  borderColor={colors.darkerGray}
                  hoverColor={colors.darkerGray}
                  className={`${cls.button} ${cls.endButton}`}
                  disabled={currentPage + 1 >= lastPage}
                >
                  <i className="fas fa-step-forward fa-fw" />
                </NormalButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return null;
  }
}

export default PaginationList;
