import React, { useState, useEffect } from "react";
import { equipReviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import ReviewWork from "./reviewWork.js";

export default function ReviewWorkLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [learners, setLearners] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Equip Review");
      setActionItems(d.actionItems);
      setLearners(d.learnersWithUserPaths);
      
      setPrograms(
        Array.from(new Set(d.actionItems.map(l => l.userPath.title))).map(x => {
          return { id: x, name: x };
        })
      );
      let userIds = d.learnersWithUserPaths.map(l => l.user.id);
      setGroups(d.sharingGroups.filter(x => x.users.map(y => y.id).some(z => userIds.includes(z))));
      setLoading(false);
    }

    equipReviewData.load(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return <ReviewWork name="Equip Review" actionItems={actionItems} learners={learners} programs={programs} groups={groups} />;
}
