import React, { useEffect, useState } from "react";
import { colors, Blink, BTypography, PathProgress, PaginationList, SortableList, UserAvatar, FilterTextField, FormAutocompleteField, NormalButton, PopTip, ProgramAvatar } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  actionItems: props => ({
    margin: "1rem"
  }),
  groupFilter: { paddingLeft: "0.25rem", minWidth: "10rem" },
  programFilter: { paddingLeft: "0.25rem", minWidth: "10rem" },
  filterWrapper: { paddingLeft: "0.25rem" },
  sharedGroupButton: { padding: "0.5rem 0 0 0.5rem", },
  header: { padding: "1rem", backgroundColor: colors.white },
  body: { paddingTop: "1rem" },
  overview: { borderBottom: `1px solid ${colors.black}` },
  column: {
    borderRight: `1px solid ${colors.black}`,
    textAlign: "center",
    padding: "0.5rem",
    maxHeight: "20rem",
    overflowY: "auto",
    "&:last-child": { borderRight: "none" }
  },
  avatarName: { paddingLeft: "0.25rem", overflowWrap: "normal" },
  path: { paddingRight: "0.25rem" },
  subtitle: { paddingLeft: "0.25rem", colors: colors.bildBlue },
  pathTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "1",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  groupTitle: { color: colors.bildGray },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "1",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  button: { backgroundColor: colors.orange }
});

export default function ReviewWork({ name, actionItems, programs, groups }) {
  const cls = useStyles({ actionItems });
  const [_actionItems, _setActionItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState(null);
  const [programFilter, setProgramFilter] = useState(null);

  useEffect(() => {
    // map actionItems
    if (actionItems) {
      _setActionItems(
        actionItems
          .filter(l => {
            if (filter.length > 0) {
              return l.user.name.toLowerCase().includes(filter.toLowerCase())
              || l.userPath.title.toLowerCase().includes(filter.toLowerCase());
            } else {
              return 1;
            }
          })
          .filter(l => {
            if (groupFilter) {
              return groups.filter(g => g.id === groupFilter)[0].users.some(u => u.id === l.user.id);
            } else {
              return 1;
            }
          })
          .filter(l => {
            if (programFilter && programFilter.length > 0) {
              return l.userPath.title === programFilter;
            } else {
              return 1;
            }
          })
          .map(x => [
            x.userLearningItem.updatedAt,
            [
              <NormalButton
                variant="simple"
                dst="manage-user"
                user_id={x.user.id}
                sortval={x.user.name} 
                key={x.user.id} 
              >
                <UserAvatar size={30} src={x.user.avatarURL} name={x.user.name} nameVariant={"body1"} srcSize="small" badgeSrc={x.user.badgeURL}/>
              </NormalButton>
            ],
            [
              <Grid container direction="column" justifyContent="center" alignItems="stretch" sortval={x.userLearningItem.name} key={"00"}>
                <Grid container item xs={12} direction="column" justifyContent="center" alignItems="stretch">
                  <NormalButton
                    variant="simple"
                    dst="equip-user-path-item"
                    user_path_item_id={x.userLearningItem.userPathId}
                    className={cls.link}
                    sortval={`${x.learningGroup.name} - ${x.userLearningItem.name}`}
                    key={x.userLearningItem.userPathId}
                  >
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                      <Grid container item xs>
                        <Grid item xs={12} className={cls.pathTitle} style={{ color: x.userPath.programColor ? x.userPath.programColor.webFrontendValue : colors.bildGray }}>
                          <BTypography variant="body2">{x.userPath.title}</BTypography>
                        </Grid>
                        <Grid item xs={12} className={cls.itemTitle}>
                          <BTypography variant="body1">{x.userLearningItem.name}</BTypography>
                        </Grid>
                      </Grid>
                      <Grid item className={cls.itemButtonRight}>
                        <i className="fas fa-chevron-right" />
                      </Grid>
                    </Grid>
                  </NormalButton>
                </Grid>
              </Grid>
            ],
          ])
      );
    }
  }, [actionItems, filter, groupFilter, programFilter]);

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="center">
        <Grid item>
          <BTypography variant="h4"><i className="fal fa-check-circle" /> {name ? name : "Progress View"}</BTypography>
        </Grid>
        <Grid container item md sm={12} justifyContent="flex-end" alignItems="center">
          <Grid item className={cls.groupFilter}>
            <FormAutocompleteField placeholder="Group" value={groupFilter} setValue={setGroupFilter} items={groups} variant="small" />
          </Grid>
          <Grid item className={cls.programFilter}>
            <FormAutocompleteField placeholder="Program" value={programFilter} setValue={setProgramFilter} items={programs} variant="small" />
          </Grid>
          <Grid item className={cls.filterWrapper}>
            <FilterTextField
              value={filter}
              placeholder="Search"
              onChange={e => setFilter(e.target.value)}
              onClear={() => {
                setFilter("");
              }}
              wrapperPadding={"0"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.actionItems}>
        <SortableList
          perPage={9}
          filter={filter}
          headers={_actionItems.length > 0 ? ["Waiting Since", "Name", "Item"] : []}
          items={_actionItems}
          spacing={[2, 3, 7]}
          itemPadding={"0.4rem 1rem 0.2rem 1rem"}
          itemType={"Assessments"}
          emptyMessage={
            <BTypography variant="h6">
              {filter || programFilter ? "No Users/Programs match the search criteria." : "Nothing is waiting on you!"}
            </BTypography>
          }
          fixedHeight={"65vh"}
        />
      </Grid>
    </Grid>
  );
}
